import "@fontsource/urbanist";
import "@fontsource/overpass";

import { extendTheme, extendBaseTheme, ThemeConfig } from "@chakra-ui/react";
const config: ThemeConfig = {
  initialColorMode: "system",
  useSystemColorMode: true,
};

const theme = {
  config,
  styles: {
    global: {
      html: {
        scrollBehavior: "smooth",
      },
    },
  },
  fonts: {
    heading: `'Urbanist', sans-serif`,
    body: `'Overpass', sans-serif`,
  },
  components: {
    Button: {
      baseStyle: {
        fontFamily: `'Urbanist', sans-serif`,
        letterSpacing: `0.05rem`,
      },
    },
    Text: {
      variants: {
        overpass: {
          fontFamily: `'Urbanist', sans-serif`,
          letterSpacing: `0.05rem`,
        },
      },
    },
  },
};

export default extendTheme(theme); // or extendBaseTheme

export const baseTheme = extendBaseTheme();
